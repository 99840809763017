import * as $ from 'jquery';
import { ViewModels, ActivityLogEntry, User, Report } from '../models/models';

import AppSettings from '../appSettings';
import SpaAuthService from './SpaAuthService';
import Swal from 'sweetalert2';

export default class AppOwnsDataWebApi {

  static LoginUser = async (LoginId: string, UserName: string) => {

    var user = new User();
    user.LoginId = LoginId;
    user.UserName = UserName;

    console.log("Process user login", user);

    var accessToken: string = await SpaAuthService.getAccessToken();
    var postData: string = JSON.stringify(user);

    var restUrl = AppSettings.apiRoot + "UserLogin/";
    return $.ajax({
      url: restUrl,
      method: "POST",
      contentType: "application/json",
      data: postData,
      crossDomain: true,
      headers: {
        "Accept": "application/json",
        "Authorization": "Bearer " + accessToken
      }
    });
  }

  static GetEmbeddingData = async (): Promise<ViewModels> => {

    var accessToken: string = await SpaAuthService.getAccessToken();
    var restUrl = AppSettings.apiRoot + "Embed/";

    return $.ajax({
      url: restUrl,
      crossDomain: true,
      headers: {
        "Accept": "application/json;",
        "Authorization": "Bearer " + accessToken
      }
    });
  }

  static GetEmbedToken = async (): Promise<string> => {

    var accessToken: string = await SpaAuthService.getAccessToken();
    var restUrl = AppSettings.apiRoot + "EmbedToken/";

    return $.ajax({
      url: restUrl,
      crossDomain: true,
      headers: {
        "Accept": "application/json;",
        "Authorization": "Bearer " + accessToken
      }
    });
  }

  static ExportFile = async (report: Report, exportType: string, bookmarkState?: string) => {

    console.log("Starting export");

    var accessToken: string = await SpaAuthService.getAccessToken();
    let data = {
      reportId: report.id,
      datasetId: report.datasetId,
      exportType: exportType,
      filter: "",
      bookmarkState: bookmarkState,
      pageName: "",
      visualValue: ""
    };

    var restUrl = AppSettings.apiRoot + "ExportFile/";
    const response = await fetch(restUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + accessToken
        },
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const contentDisposition = response.headers.get("Content-Disposition");
    let filename = "report_export.pdf";
    if (contentDisposition)
    {
       filename = contentDisposition.split(';')
                            .find(n => n.includes('filename='))!
                            .replace('filename=', '')
                            .trim()
                            .replace(/"/g, ''); // Removes any double quotes around the filename
    }

    const blob = await response.blob();
    return { blob, filename };
  }


  static LogActivity = async (activityLogEntry: ActivityLogEntry) => {

    var accessToken: string = await SpaAuthService.getAccessToken();
    var postData: string = JSON.stringify(activityLogEntry);

    var restUrl = AppSettings.apiRoot + "ActivityLog/";
    return $.ajax({
      url: restUrl,
      method: "POST",
      contentType: "application/json",
      data: postData,
      crossDomain: true,
      headers: {
        "Accept": "application/json",
        "Authorization": "Bearer " + accessToken
      }
    });
  }

  static Clone = async (reportToClone: Report): Promise<Report> => {

    var accessToken: string = await SpaAuthService.getAccessToken();
    let postData = JSON.stringify(reportToClone);

    var restUrl = AppSettings.apiRoot + "Report/Clone";
    return $.ajax({
      url: restUrl,
      type: "POST",
      data: postData,
      crossDomain: true,
      contentType: "application/json",
      headers: {
        "Accept": "application/json",
        "Authorization": "Bearer " + accessToken
      }
    });
  }

  static Create = async (reportName: string): Promise<Report> => {

    var accessToken: string = await SpaAuthService.getAccessToken();
    let postData = JSON.stringify({ name: reportName });

    var restUrl = AppSettings.apiRoot + "Report/Create";
    return $.ajax({
      url: restUrl,
      type: "POST",
      data: postData,
      crossDomain: true,
      contentType: "application/json",
      headers: {
        "Accept": "application/json",
        "Authorization": "Bearer " + accessToken
      }
    });
  }

  // Call delete report API
  static Delete = async (reportId: string) => {
    try {
        var accessToken: string = await SpaAuthService.getAccessToken();
        var restUrl = AppSettings.apiRoot + "Report/Delete/?reportId=" + reportId;

        return $.ajax({
            url: restUrl,
            type: "DELETE",
            crossDomain: true,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + accessToken
            }
        }).fail((jqXHR, textStatus, errorThrown) => {
          console.error("Request failed: ", textStatus, errorThrown);
          Swal.fire({
            text: "Der opstod en fejl ved sletningen af rapporten",
            icon: "error"
          });
        });
    } catch (error) {
        // Handle errors from getAccessToken or other unexpected errors
        console.error("A general error occurred: ", error);
        Swal.fire({
          text: "Der opstod en fejl ved sletningen af rapporten",
          icon: "error"
        });
    }
}
}
  