export default class AppSettings {

  // replace with production URL after deploying Web API
    // public static apiRoot: string = "https://localhost:44302/api/"; 
    // public static apiRoot: string = "https://app-webapi-landingzone-forsyningbi-dev.azurewebsites.net/api/"; 
    public static apiRoot: string = "https://app-forsyningbi-webapi-prod.azurewebsites.net/api/"; 

  // setting for Azure AD app which supports SPA authentication
  public static tenant: string = "9107df94-9d75-40c0-9d48-368368de31d9";
  public static clientId: string = "9fe8f113-da32-45f2-bdc0-b78f7ab271a5";
 
  // permission scopes required with App-Owns-Data Web API
  public static apiScopes: string[] = [
    "api://" + AppSettings.clientId + "/Reports.Embed"
  ];

}