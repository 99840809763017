import 'bootstrap';

import * as $ from 'jquery';

import * as powerbi from "powerbi-client";
import * as pbimodels from "powerbi-models";

require('powerbi-models');
require('powerbi-client');

import SpaAuthService from './services/SpaAuthService';
import AppOwnsDataWebApi from './services/AppOwnsDataWebApi'
import { Report, Dataset, ViewModel, ViewModels, ActivityLogEntry } from './models/models';

import Swal from 'sweetalert2';

export default class App {

  // fields for UI elemenets in DOM
  private static mainBody: JQuery;
  private static topBanner: JQuery;
  private static userGreeting: JQuery;
  private static login: JQuery;
  private static logout: JQuery;
  private static viewAnonymous: JQuery;
  private static viewUnassigned: JQuery;
  private static viewBlocked: JQuery;
  private static loadingSpinner: JQuery;
  private static loadingSpinnerMessage: JQuery;
  private static viewAuthenticated: JQuery;
  private static viewAuthenticatedHeader: JQuery;
  private static tenantName: JQuery;
  private static reportsList: JQuery;
  private static standardReportsList: JQuery;
  private static createDefaultReportLink: JQuery;
  // private static datasetsList: JQuery;
  private static datasetsListContainer: JQuery;
  private static embedToolbar: JQuery;
  private static breadcrumb: JQuery;
  private static toggleEditButton: JQuery;
  private static exportButton: JQuery;
  private static fullScreenButton: JQuery;
  private static cloneReportButton: JQuery;
  private static deleteReportButton: JQuery;
  private static embedContainer: JQuery;
  private static resizedFinished: any;
  private static currentReport: powerbi.Report;
  private static layoutMode: "master" | "mobile";
  private static breakPointWidth: number = 576;

  private static powerbi: powerbi.service.Service = window.powerbi;
  //private static viewModel: ViewModel;
  private static viewModels: ViewModels;

  public static onDocumentReady = () => {

    // initialize fields for UI elemenets 
    App.mainBody = $("#main-body");
    App.topBanner = $("#top-banner");
    App.userGreeting = $("#user-greeting");
    App.login = $("#login");
    App.logout = $("#logout");
    App.viewAnonymous = $("#view-anonymous");
    App.viewUnassigned = $("#view-unassigned");
    App.viewBlocked = $("#view-blocked");
    App.loadingSpinner = $("#view-loading-spinner");
    App.loadingSpinnerMessage = $("#spinner-message");
    App.viewAuthenticated = $("#view-authenticated");
    App.viewAuthenticatedHeader = $("#view-authenticated-header");
    App.tenantName = $("#tenant-name");
    App.reportsList = $("#reports-list");
    App.standardReportsList = $("#standard-reports-list");
    App.createDefaultReportLink = $("#create-default-report");
    // App.datasetsList = $("#datasets-list");
    App.datasetsListContainer = $("#datasets-list-container");
    App.embedToolbar = $("#embed-toolbar");
    App.breadcrumb = $("#breadcrumb");
    App.toggleEditButton = $("#toggle-edit");
    App.exportButton = $("#export");
    App.fullScreenButton = $("#full-screen");
    App.cloneReportButton = $("#clone-report");
    App.deleteReportButton = $("#delete-report");
    App.embedContainer = $("#embed-container");

    // set up authentication callback
    SpaAuthService.uiUpdateCallback = App.onAuthenticationCompleted;

    App.login.on("click", async () => {
      await SpaAuthService.login();
    });

    App.logout.on("click", () => {
      SpaAuthService.logout();
      App.refreshUi();
    });

    // Comment out to disable auto-authentication on startup
    SpaAuthService.attemptSillentLogin();

    App.refreshUi();

    App.registerWindowResizeHandler();
  }

  private static refreshUi = () => {

    if (SpaAuthService.userIsAuthenticated) {
      App.userGreeting.text("Velkommen " + SpaAuthService.userDisplayName);
      App.userGreeting.prop('title', 'Email: ' + SpaAuthService.userName);
      App.login.hide()
      App.logout.show();
      App.viewAnonymous.hide();
    }
    else {
      App.userGreeting.text("");
      App.login.show();
      App.logout.hide();
      App.viewAnonymous.show();
      App.viewAuthenticated.hide();
      App.viewBlocked.hide();
    }
  }

  private static onAuthenticationCompleted = async () => {
    App.loadingSpinnerMessage.text("Logger ind ...");
    App.loadingSpinner.show(250);
    App.viewAnonymous.hide();
    var response = await AppOwnsDataWebApi.LoginUser(SpaAuthService.userName, SpaAuthService.userDisplayName);
    App.loadingSpinner.hide();
    App.refreshUi();
    
    if (response === "Blocked" || response === "NoAccess") {
      App.viewBlocked.show(500);
      return;
    }

    App.initializeAppData();
  }

  private static initializeAppData = async () => {

    App.loadingSpinnerMessage.text("Indlæser rapporter ...");
    App.loadingSpinner.show();
    App.viewAnonymous.hide();
    
    App.viewModels = await AppOwnsDataWebApi.GetEmbeddingData(); 
      
    if (App.viewModels === undefined) {
     App.viewAnonymous.hide();
     App.viewAuthenticated.hide();
     App.loadingSpinner.hide();
     App.viewUnassigned.show(500);
    }
    else {
     console.log("Loading View Model", App.viewModels);
     App.loadViewModel(App.viewModels);
    }    

    window.setInterval(App.reportOnExpiration, 10000);
  }

  private static findViewModelBasedOnReport = (report: Report) =>
  {
    var foundReportInStandardList = App.viewModels.standardEmbeddedViewModel.reports.find(x => x.id === report.id) !== undefined;
    console.log(`${foundReportInStandardList ? 'Standard' : 'Tenant'} Workspace > ${report.name}`);
    if (foundReportInStandardList) 
      return App.viewModels.standardEmbeddedViewModel;

    return App.viewModels.tenantEmbeddedViewModel;    
  }

  private static findViewModelBasedOnDataset = (dataset: Dataset) =>
  {
    var foundDatasetInStandardList = App.viewModels.standardEmbeddedViewModel.datasets.find(x => x.id === dataset.id) !== undefined;
    console.log(`${foundDatasetInStandardList ? 'Standard' : 'Tenant'} Workspace > ${dataset.name}`);
    if (foundDatasetInStandardList) 
      return App.viewModels.standardEmbeddedViewModel;

    return App.viewModels.tenantEmbeddedViewModel;
  }

  private static findReport = (reportId: string) => 
  {
    let foundReport: Report = App.viewModels.standardEmbeddedViewModel.reports.find((report) => report.id === reportId)!;
    if (foundReport === null)
      foundReport = App.viewModels.tenantEmbeddedViewModel.reports.find((report) => report.id === reportId)!;

    return foundReport;
  }

  private static loadViewModel = async (viewModels: ViewModels, reportId?: string, ) => {

    App.viewAuthenticated.hide();
    App.viewUnassigned.hide();
    
    App.powerbi.reset(App.embedContainer[0]);

    App.tenantName.text(viewModels.tenantEmbeddedViewModel.tenantName);
    App.reportsList = App.reportsList.empty();
    App.standardReportsList = App.standardReportsList.empty();
    // App.datasetsList = App.datasetsList.empty();

    if (viewModels.standardEmbeddedViewModel.reports.length == 0) {
      App.standardReportsList.append($("<li>")
        .text("Der er ikke fundet nogen standardrapporter")
        .addClass("no-content"));
    }
    else {
      viewModels.standardEmbeddedViewModel.reports.forEach((report: Report) => {
        var li = $("<li>");
        li.append($("<i>").addClass("custom-icon icon-" + report.category.toLowerCase()));
        li.append($("<a>", {
          "href": "javascript:void(0);"
        }).text(report.name).click(() => { App.embedReport(report) }));
        App.standardReportsList.append(li);
      });
    }

    if (viewModels.tenantEmbeddedViewModel.reports.length == 0) {
      App.reportsList.append($("<li>")
        .text("Ingen rapporter")
        .addClass("no-content"));
    }
    else {
      viewModels.tenantEmbeddedViewModel.reports.forEach((report: Report) => {
        var li = $("<li>");
        li.append($("<i>").addClass("custom-icon icon-demography"));
        li.append($("<a>", {
          "href": "javascript:void(0);"
        }).text(report.name).click(() => { App.embedReport(report) }));
        App.reportsList.append(li);
      });
    }
    
    // if (viewModels.standardEmbeddedViewModel.userCanCreate) {
    //   if (viewModels.standardEmbeddedViewModel.datasets.length == 0) {
    //     App.datasetsList.append($("<li>")
    //       .text("Der er ikke fundet nogle standard datasæts")
    //       .addClass("no-content"));
    //   }
    //   else {
    //     viewModels.standardEmbeddedViewModel.datasets.forEach((dataset: Dataset) => {
    //       var li = $("<li>");
    //       li.append($("<i>").addClass("fa fa-database"));
    //       li.append($("<a>", {
    //         "href": "javascript:void(0);"
    //       }).text(dataset.name).click(() => { App.embedNewReport(dataset) }));
    //       App.datasetsList.append(li);
    //     });
    //   }
    // }

    App.createDefaultReportLink.on("click", async () => {
      
      const createReportFunc = async (reportName: string) => {
        App.InsertReportInUi(await AppOwnsDataWebApi.Create(reportName));
      };      
      App.ShowDisclaimer(createReportFunc);
      
    });

    App.loadingSpinner.hide();
    App.viewAuthenticated.show();    

    // Load clicked report OR if none selected, load landing page report
    if (reportId !== undefined) {
      let newReport: Report = viewModels.standardEmbeddedViewModel.reports.find((report) => report.id === reportId)!;
      if (newReport === null)
        newReport = viewModels.tenantEmbeddedViewModel.reports.find((report) => report.id === reportId)!;

      App.embedReport(newReport, true);
    }
    else {
      var newReport: Report = viewModels.standardEmbeddedViewModel.reports[0];
      App.embedReport(newReport, false);
    }

  }

  private static embedReport = async (report: Report, editMode: boolean = false) => {

    App.setReportLayout();

    var models = pbimodels;
    
    var viewModel = this.findViewModelBasedOnReport(report);

    var permissions;
    if (viewModel.userCanEdit && viewModel.userCanCreate) {
      permissions = models.Permissions.All;
    }
    else if (viewModel.userCanEdit && !viewModel.userCanCreate) {
      permissions = models.Permissions.ReadWrite;
    }
    else if (!viewModel.userCanEdit && viewModel.userCanCreate) {
      permissions = models.Permissions.Copy;
    }
    else if (!viewModel.userCanEdit && !viewModel.userCanCreate) {
      permissions = models.Permissions.Read;
    }

    App.setLayoutMode();
    var layoutMode: pbimodels.LayoutType =
      App.layoutMode == "master" ?
        models.LayoutType.Master :
        models.LayoutType.MobilePortrait;

    var config: powerbi.IReportEmbedConfiguration = {
      type: 'report',
      id: report.id,
      embedUrl: report.embedUrl,
      accessToken: viewModel.embedToken,
      tokenType: models.TokenType.Embed,
      permissions: permissions,
      viewMode: editMode ? models.ViewMode.Edit : models.ViewMode.View,
      settings: {
        layoutType: layoutMode,
        // background: models.BackgroundType.Transparent,
        
        panes: {
          filters: { visible: true },
          pageNavigation: { visible: true, position: models.PageNavigationPosition.Left }
        }
      }
    };

    App.powerbi.reset(App.embedContainer[0]);

    var timerStart: number = Date.now();
    var initialLoadComplete: boolean = false;
    var loadDuration: number;
    var renderDuration: number;
    App.currentReport = <powerbi.Report>App.powerbi.embed(App.embedContainer[0], config);

    App.currentReport.off("loaded")
    App.currentReport.on("loaded", async (event: any) => {
      loadDuration = Date.now() - timerStart;
      App.setReportLayout();
    });

    App.currentReport.off("rendered");
    App.currentReport.on("rendered", async (event: any) => {

      if (!initialLoadComplete) {
        renderDuration = Date.now() - timerStart;
        var correlationId: string = await App.currentReport.getCorrelationId();
        await App.logViewReportActivity(correlationId, viewModel, report, loadDuration, renderDuration);
        initialLoadComplete = true;
      }

    });

    App.currentReport.off("saved");
    App.currentReport.on("saved", async (event: any) => {
      if (event.detail.saveAs) {
        console.log("SaveAs Event", event);
        var orginalReportId = report.id;
        var reportId: string = event.detail.reportObjectId;
        var reportName: string = event.detail.reportName;
        await App.logCopyReportActivity(report, reportId, reportName, viewModel.embedTokenId);
        App.viewModels = await AppOwnsDataWebApi.GetEmbeddingData();
        App.loadViewModel(App.viewModels, reportId);
      }
      else {
        console.log("Save Event", event);      
        await App.logEditReportActivity(report, viewModel.embedTokenId);
      }
    });

    var viewMode = editMode ? "edit" : "view";

    App.breadcrumb.text(`${viewModel.isStandardWorkspace ? 'Standardrapporter' : 'Jeres rapporter'} > ${report.name}`);

    if (viewModel.userCanCreate && viewModel.isStandardWorkspace)
    {
      App.cloneReportButton.show();
      
      App.cloneReportButton.off();
      App.cloneReportButton.on("click", async () => {

        const cloneReportFunc = async (reportName: string) => {
          var reportModel = new Report();
          reportModel.id = report.id;
          reportModel.name = reportName;

          let clonedReport = await AppOwnsDataWebApi.Clone(reportModel);
          App.InsertReportInUi(clonedReport);
        };
        App.ShowDisclaimer(cloneReportFunc, report.name);

      });
    }
    else
    {
      App.cloneReportButton.hide();      
    }

    if (viewModel.isStandardWorkspace || !viewModel.userCanEdit || report.reportType != "PowerBIReport") {
      App.toggleEditButton.hide();
    }
    else {
      App.toggleEditButton.show();
      App.toggleEditButton.off();
      App.toggleEditButton.on("click", () => {
        // toggle between view and edit mode
        viewMode = (viewMode == "view") ? "edit" : "view";
        App.currentReport.switchMode(viewMode);
        // show filter pane when entering edit mode
        var showFilterPane = (viewMode == "edit");
        App.currentReport.updateSettings({
          panes: {
            filters: { visible: showFilterPane, expanded: false }
          }
        });
      });
    }

    if (viewModel.isStandardWorkspace || !viewModel.userCanCreate || report.reportType != "PowerBIReport") {
      App.deleteReportButton.hide();
    }
    else {
      App.deleteReportButton.show();
      App.deleteReportButton.off();
      App.deleteReportButton.on("click", () => {

        Swal.fire({
          title: `Ønsker du at slette rapporten "${report.name}"?`,
          confirmButtonColor: '#285953',          
          confirmButtonText: "Slet",
          showCancelButton: true,
          cancelButtonText: "Fortryd",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await AppOwnsDataWebApi.Delete(report.id);

            // Update UI after report has been deleted
            App.viewModels.tenantEmbeddedViewModel.reports = App.viewModels.tenantEmbeddedViewModel.reports.filter(x => x.id !== report.id);
            App.loadViewModel(App.viewModels);  
          }
        });
      });
    }

    App.fullScreenButton.on("click", () => {
      App.currentReport.fullscreen();
    });    

    App.exportButton.off("click");
    App.exportButton.on("click", async () => {

      const currentBookmarkState = await App.currentReport.bookmarksManager.capture();

      Swal.fire({
        title: "Vælg filformat til eksport",
        input: "radio",
        inputOptions: {
          "PowerPoint": "PowerPoint",
          "PDF": "PDF",
          "Image": "Billede"
        },
        inputValue: "PowerPoint",
        inputValidator: (value) => {
          if (!value) {
            return "Vælg venligst et format, for at starte eksporteringen!";
          }
        },
        confirmButtonColor: '#285953',
        showCancelButton: !Swal.isLoading(),
        cancelButtonText: "Fortryd",
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: (exportType) => {
          Swal.showLoading();
          
          let footer = Swal.getFooter();
          footer?.append("Vent venligst mens eksporten er i gang");

          return AppOwnsDataWebApi
            .ExportFile(report, exportType, currentBookmarkState.state)
            .then(({ blob, filename }) => {
              if (Swal.isVisible())
              {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
              }
            })
            .then(() => {
              Swal.hideLoading();
            })
            .catch(error => 
              {
                Swal.hideLoading();
  
                Swal.fire({
                  icon: "error",
                  text: "Der opstod desværre en fejl",
                  confirmButtonColor: '#285953'
                });
                
                console.error('Error:', error)
              })
        }        
      });
    });
  }

  private static embedNewReport = (dataset: Dataset) => {

    var models = pbimodels;
    
    let viewModel = App.findViewModelBasedOnDataset(dataset);

    var config: powerbi.IEmbedConfiguration = {
      datasetId: dataset.id,
      embedUrl: "https://app.powerbi.com/reportEmbed",
      accessToken: viewModel.embedToken,
      tokenType: models.TokenType.Embed,
      settings: {
        panes: {
          filters: { visible: true, expanded: false }
        }
      }
    };

    console.log("Loading dataset " + dataset.name);

    // Embed the report and display it within the div container.
    App.powerbi.reset(App.embedContainer[0]);
    var embeddedReport = App.powerbi.createReport(App.embedContainer[0], config);

    $("#breadcrumb").text("Datasæts > " + dataset.name + " > Ny rapport");
    $("#embed-toolbar").show();

    $("#toggle-edit").hide();
    $("#full-screen").off("click");
    $("#full-screen").on("click", () => {
      embeddedReport.fullscreen();
    });
    App.cloneReportButton.hide();

    // handle save action on new report
    embeddedReport.on("saved", async (event: any) => {
      console.log("Create Report Event", event);
      var reportId: string = event.detail.reportObjectId;
      var reportName: string = event.detail.reportName;
      await App.logCreateReportActivity(dataset, reportId, reportName, viewModel);
      App.viewModels = await AppOwnsDataWebApi.GetEmbeddingData();
      App.loadViewModel(App.viewModels, reportId);
    });

  };

  private static setLayoutMode = () => {
    let useMobileLayout: boolean = (App.mainBody.width()! < App.breakPointWidth);
    App.layoutMode = useMobileLayout ? "mobile" : "master";
  };

  private static setReportLayout = async () => {

    let useMobileLayout: boolean = (App.mainBody.width()! < App.breakPointWidth);
    // check to see if layout mode switches between master and mobile
    if ((useMobileLayout && App.layoutMode == "master") ||
      (!useMobileLayout && App.layoutMode == "mobile")) {
     if (App.currentReport) {
        console.log("switching layout mode...")
        App.layoutMode = useMobileLayout ? "mobile" : "master";
        let sameReport: Report = this.findReport(await App.currentReport.getId());
        App.embedReport(sameReport);
      }
    }
    else {
      var models = pbimodels;
      if (useMobileLayout) {
        App.tenantName.hide();
        App.toggleEditButton.hide();
        App.fullScreenButton.hide();
        // App.datasetsListContainer.hide();
        $(App.embedContainer).height($(App.embedContainer).width()! * 3);
      }
      else {
        // set to landscape for full browser view
        App.tenantName.show();
        App.fullScreenButton.show();

        if (App.viewModels.tenantEmbeddedViewModel && App.viewModels.tenantEmbeddedViewModel.userCanCreate) {
          App.datasetsListContainer.show();
        }
        else {
          App.datasetsListContainer.hide();
        }
        let availableHeight: number = $(window).height()! - (App.topBanner.height()! + App.viewAuthenticatedHeader.height()!) - 8;
        let heightFromWidth = $(App.embedContainer).width()! * (9/16);
        let height = Math.min(availableHeight, heightFromWidth);
        $(App.embedContainer).height(height);
      }
    }

  };

  private static logViewReportActivity = async (correlationId: string, viewModel: ViewModel, report: Report, loadDuration: number, renderDuration) => {

    var logEntry: ActivityLogEntry = new ActivityLogEntry();
    logEntry.CorrelationId = correlationId;
    logEntry.EmbedTokenId = viewModel.embedTokenId;
    logEntry.Activity = "ViewReport";
    logEntry.LoginId = viewModel.user;
    logEntry.Tenant = viewModel.tenantName;
    logEntry.Report = report.name;
    logEntry.ReportId = report.id;
    logEntry.DatasetId = report.datasetId;
    let foundDataSet = viewModel.datasets.find((dataset) => dataset.id === report.datasetId);
    logEntry.Dataset = foundDataSet ? foundDataSet.name : "Unknown";
    logEntry.LoadDuration = loadDuration;
    logEntry.RenderDuration = renderDuration;
    await AppOwnsDataWebApi.LogActivity(logEntry);
  };

  private static logEditReportActivity = async (report: Report, embedTokenId: string) => {
    var logEntry: ActivityLogEntry = new ActivityLogEntry();
    logEntry.CorrelationId = "";
    logEntry.Activity = "EditReport";
    logEntry.LoginId = App.viewModels.tenantEmbeddedViewModel.user;
    logEntry.Tenant = App.viewModels.tenantEmbeddedViewModel.tenantName;
    logEntry.Report = report.name;
    logEntry.ReportId = report.id;
    logEntry.DatasetId = report.datasetId;
    logEntry.EmbedTokenId = embedTokenId;
    let foundDataSet = App.viewModels.tenantEmbeddedViewModel.datasets.find((dataset) => dataset.id === report.datasetId);
    logEntry.Dataset = foundDataSet ? foundDataSet.name : "Unknown";
    await AppOwnsDataWebApi.LogActivity(logEntry);
  };

  private static logCopyReportActivity = async (orginalReport: Report, reportId: string, reportName, embedTokenId: string) => {
    var logEntry: ActivityLogEntry = new ActivityLogEntry();
    logEntry.Activity = "CopyReport";
    logEntry.LoginId = App.viewModels.tenantEmbeddedViewModel.user;
    logEntry.Tenant = App.viewModels.tenantEmbeddedViewModel.tenantName;
    logEntry.Report = reportName;
    logEntry.ReportId = reportId;
    logEntry.OriginalReportId = orginalReport.id;
    logEntry.DatasetId = orginalReport.datasetId;
    logEntry.EmbedTokenId = embedTokenId;
    let foundDataSet = App.viewModels.tenantEmbeddedViewModel.datasets.find((dataset) => dataset.id === orginalReport.datasetId);
    logEntry.Dataset = foundDataSet ? foundDataSet.name : "Unknown";
    await AppOwnsDataWebApi.LogActivity(logEntry);
  };

  private static logCreateReportActivity = async (dataset: Dataset, reportId: string, reportName, viewModel: ViewModel) => {
    var logEntry: ActivityLogEntry = new ActivityLogEntry();
    logEntry.Activity = "CreateReport";
    logEntry.LoginId = viewModel.user;
    logEntry.Tenant = viewModel.tenantName;
    logEntry.Report = reportName;
    logEntry.ReportId = reportId;
    logEntry.DatasetId = dataset.id;
    logEntry.Dataset = dataset.name;
    logEntry.EmbedTokenId = viewModel.embedTokenId;
    await AppOwnsDataWebApi.LogActivity(logEntry);
  };

  private static registerWindowResizeHandler = async () => {
    $(window).resize(async function () {
      clearTimeout(App.resizedFinished);
      App.resizedFinished = setTimeout(async function () {
        App.setReportLayout();
      }, 100);
    });
  }

  private static reportOnExpiration = async () => {
    // #TODO Only checking one expire token
    var secondsToExpire = Math.floor((new Date(App.viewModels.standardEmbeddedViewModel.embedTokenExpiration).getTime() - new Date().getTime()) / 1000);   
    var minutes = Math.floor(secondsToExpire / 60);
    var seconds = secondsToExpire % 60;
    var timeToExpire = minutes + ":" + seconds;
    console.log("Token expires in ", timeToExpire);
    
    if (secondsToExpire <= 30)
    {
      console.log("Refresh report tokens ..");
      try {
        App.viewModels = await AppOwnsDataWebApi.GetEmbeddingData();
      }
      catch (e) 
      {
        // If the computer was sleeping and woken up again, 
        // the page sometimes unsuccesfully tries to open a login prompt repeatly.
        // If something like this happens (or any other hard error), reload portal.
        location.reload();
      }

      var dummyReport = new Report();
      dummyReport.id = await App.currentReport.getId();
      dummyReport.name = "[refreshed report]";
      var viewModel = this.findViewModelBasedOnReport(dummyReport);
      App.currentReport.setAccessToken(viewModel.embedToken);

      console.log("Done");
    }
  };

  private static ShowDisclaimer = (GenerateReport: Function, suggestedReportName?: string) => 
  {
    Swal.fire({
      html: "<h2>Du skal nu til at bygge din egen rapport</h2><br />At bygge rapporter kan kræve færdigheder i PowerBI<br /><br />Du kan se hjælpevideoer om at bygge dine egne rapporter <a target='_blank' href='https://www.softvaerket.dk/loesninger-og-services/business-intelligence/byg-dine-egne-rapporter-traeningsvideoer/' class='clickable-link underline'>her</a><br /><br /><div class='disclaimer-body'>Support på at bygge rapporter er ikke en del af dit abonnement. Hvis du gerne vil have Softværkets assistance til at bygge din rapport, kan du købe dette pr. timebasis. Ønsker du efterfølgende support og vedligehold på dine specialrapporter, kan du købe et abonnement, der koster 35% af rapportens oprindelige pris pr. år. Du kan også sagtens selv gå i gang. Det koster ikke noget</div><br />God fornøjelse.",
      input: "text",
      inputValue: suggestedReportName,
      inputLabel: "Indtast navnet på din rapport",
      inputValidator: (value) => {
        if (!value) {
          return "Indtast venligst navnet på din rapport!";
        }
        if (value.length > 300) {
          return "Rapportnavnet er for langt!"
        }
        const notAllowedChars = ".,;':/\\*|?&%$!+=()[]{}<>";
        for (let i = 0; i < value.length; i++) {
          if (notAllowedChars.includes(value[i])) {
              return "Der er ugyldige tegn i rapportnavnet!"
          }
        }
      },
      confirmButtonText: "Start",
      confirmButtonColor: '#285953',
      showCancelButton: true,
      cancelButtonText: "Fortryd",
      width: '700px'
    })
    .then(async (result) =>
    {
      if (result.isConfirmed)
      {
        GenerateReport(result.value);
      }
    });  
  }
  
  private static InsertReportInUi = async (report: Report) =>
  {
    if (report !== null)
    {
      var liSpinner = 
        $("<li>")          
        .append($("<i>").addClass("fa fa-spinner fa-spin"))
        .append($("<span>")
          .text("Indlæser ..."));
      App.reportsList.append(liSpinner);

      var reportDoneLoaded = () => {
        liSpinner.remove();

        var li = $("<li>");
        li.append($("<i>").addClass("custom-icon icon-demography"));
        li.append($("<a>", {
          "href": "javascript:void(0);"
        }).text(report.name).click(() => { App.embedReport(report) }));
        App.reportsList.append(li);
      };

      // Indicate the new report has been loaded, after 5 secs of clicking clone
      setTimeout(reportDoneLoaded, 5000);

      // Refresh tokens
      App.viewModels = await AppOwnsDataWebApi.GetEmbeddingData(); 
      
      App.viewModels.tenantEmbeddedViewModel.reports.push(report);

      // Load the cloned report
      App.embedReport(report);
    }
    else
    {
      alert('An error occured');
    }

  }
}

$(App.onDocumentReady);
